.main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.headerBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0rem;
}

.seeAll {
  font-size: 18px;
  margin-bottom: 0rem;
  font-weight: 400;
  color: var(--text-body);
  font-family: "Editorial";
}

.seeAll:hover {
  color: var(--text-main);
  cursor: pointer;
}

.header {
  margin-right: auto;
  font-family: "Editorial";

  font-size: 20px;
  font-weight: 500;
  color: var(--text-main);
  margin-bottom: 0rem;
}

.Link {
  text-decoration: none;
}

@media (max-width: 600px) {
  .header {
    font-size: 18px;
  }
  .seeAll {
    font-size: 16px;
  }
}
